import React from "react"
import { Link } from "gatsby"
import Layout from "@components/layout"
import SEO from "@components/seo"
import { LazyLoadComponent } from "react-lazy-load-image-component"
import Swiper from "react-id-swiper"
import "swiper/css/swiper.css"
import PageHeadingTitle from "@components/pageHeadingTitle"

const gallery = [
  {
    image: require("@images/greenleebender2lg.jpg"),
    name: "Greenlee No. 1800 Rigid Conduit Bender",
    link: "/",
  },
  {
    image: require("@images/v18_1lg.jpg"),
    name: "Hyd-Mech V-18 Vertical Tilt Band Saw",
    link: "/",
  },
  {
    image: require("@images/webbroll_2lg.jpg"),
    name: "Webb 3/8” x 8’ Plate Roll",
    link: "/",
  },
  {
    image: require("@images/hwroll_1lg.jpg"),
    name: "Hendley & Whittemore 2-1/2” x 2-1/2” x 1/4” Angle Roll",
    link: "/",
  },
]

const params = {
  speed: 500,
  effect: "fade",
  slidesPerView: 1,
  loop: true,
  autoPlay: true,
  pagination: {
    el: ".swiper-pagination",
    type: "bullets",
    clickable: true,
  },
  navigation: {
    nextEl: "#pics-next",
    prevEl: "#pics-prev",
  },
}

/*const BlockContent = require("@sanity/block-content-to-react")
const serializers = {
  types: {
    code: props => (
      <pre data-language={props.node.language}>
        <code>{props.node.code}</code>
      </pre>
    ),
  },
}*/

const AboutPage = ({ data }) => {
  /*const page = data && data.page*/

  return (
    <Layout>
      {/*<SEO title={page.title} />*/}
      <SEO title="Company" />
      <div className="page-headline">
        <div className="container">
          <PageHeadingTitle h1={"About"} h2={"The Company"}/>
        </div>
      </div>
      <div className="page-body">
        <div className="container">
          {/*<BlockContent
              blocks={page._rawBody || []}
              serializers={serializers}
            />*/}
          <h3 style={{fontSize: '15px', fontWeight: '400', lineHeight: '26.25px', color: 'rgb(98, 98, 98)' }}>
            Established in 1973, Jim Daldos and Virginia Ebert have been
            representing an extensive line of high quality fabricating
            equipment. We specialize exclusively in structural steel and
            precision sheet metal fabricating equipment. We strive to extend our
            experience to offer our customers the most efficient and cost
            effective answers to their fabricating challenges. From shop flow to
            finished product, our long standing relationships with the factories
            we represent will help streamline your operation with the best
            equipment on the market, at the best price. We work for our
            customers ~ you!
          </h3>
          <div className="my-5 bg-light rounded border shadow">
            <div className="row align-items-center">
              <div className="col-md-6">
                <div className="p-4">
                  <h4 className="text-primary mb-4 pb-4 border-bottom">
                    Before you hire another person for your shop, add equipment
                    to make your existing crew more efficient.
                  </h4>
                  <p>
                    Paying out labor costs gives no added value to your company.
                    Equipment has tax benefits, efficiency and time saving value
                    and has a value for resale in the future. You won't match
                    Social Security, pay unemployment and health insurance or
                    workers comp. costs to the payment of machinery. 21st
                    Century business requires 21st Century thinking. Let us show
                    you the tax advantages/cost justification against labor and
                    the best use of your money.
                  </p>
                </div>
              </div>
              <div className="col-md-6 pt-5">
                <div className="px-4">
                  <div className="gallery-swiper-wrapper">
                    <div className="gallery-swiper-nav">
                      <button
                        className="btn btn-primary swiper-nav-btn gallery-prev"
                        id="pics-prev"
                      >
                        <i className="fa fa-long-arrow-left"></i>
                      </button>
                      <button
                        className="btn btn-primary swiper-nav-btn gallery-next"
                        id="pics-next"
                      >
                        <i className="fa fa-long-arrow-right"></i>
                      </button>
                    </div>
                    <LazyLoadComponent>
                      <Swiper {...params}>
                        {gallery.map(pic => (
                          <div
                            className="gallery-img-wrapper"
                            style={{
                              backgroundImage: `url(${pic.image})`,
                            }}
                          ></div>
                        ))}
                      </Swiper>
                    </LazyLoadComponent>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="text-center">
            <Link className="btn btn-primary btn-lg m-2" to="/products">
              Our Products
            </Link>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query AboutPageQuery {
    page: sanityPage(_id: { regex: "/(drafts.|)about/" }) {
      id
      title
      _rawBody
    }
  }
`

export default AboutPage
